import React from 'react';

import { FocusableInput, FocusableButton, FocusableLink, FocusableTextarea } from './Controllers';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

function Hp(props) {
  return (
    <div>
      <img src="/header.jpg" />
      <h1>History & Story</h1>

      <p>La Réserve Paris Hotel & Spa is a classic urban mansion comprising 26 suites and 14 rooms that Michel Reybier wished to imbue with the chic and confidential spirit of a club.</p>
      <p>An address bound to delight devotees of art, culture and shopping. An extraordinary view over some of the finest monuments in Paris: the Grand Palais, the Eiffel Tower, the Pantheon and the Obelisk on the Place de la Concorde.</p>
      <p>Refined service that is extremely personalised and dispensed with a sense of pleasure and simplicity within the relaxed and elegant atmosphere of a privately-held hotel of which the owner wishes his guests to feel entirely at home.</p>

      <h2>Date Renewal</h2>
      <p>The interior design has been entrusted to Jacques Garcia, offering an ideal new field of expression for the talent of this quintessentially Parisian architect.</p>
      <p>Classy yet not classic, imbued with a delightfully extravagant twist, the atmosphere of La Réserve Paris – Hotel and Spa will feature a marvellous blend of supreme refinement and warm simplicity.</p>
    </div>
  );
}

export default Hp;
