import React from 'react';
import logo from './logo.svg';
import './App.css';
import { withNavigation } from 'react-tv-navigation'

import Hp from './Hp';
import Page from './Page';
import Map from './Map';
import Checkout from './Checkout';
import Restaurant from './Restaurant';
import Contact from './Contact';

import './Typography.css';
import './App.css';

import { FocusableInput, FocusableButton, FocusableLink, FocusableTextarea, FocusableRouteLink } from './Controllers';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

function App({currentFocusPath}) {
  return (
    <Router>
      <div className="layout">
        <header className="header">
          <FocusableRouteLink href="/">Main Info</FocusableRouteLink>
          <FocusableRouteLink href="/map">Map</FocusableRouteLink>
          <FocusableRouteLink href="/checkout">Checkout</FocusableRouteLink>
          <FocusableRouteLink href="/restaurant">Restaurant</FocusableRouteLink>
          <FocusableRouteLink href="/contact">Contact us</FocusableRouteLink>
          {/*<FocusableRouteLink href="page">Page</FocusableRouteLink>*/}
        </header>

        <div>
          <Switch>
             <Route path="/page">
               <Page />
             </Route>
             <Route path="/map">
               <Map />
             </Route>
             <Route path="/checkout">
               <Checkout />
             </Route>
             <Route path="/restaurant">
               <Restaurant />
             </Route>
             <Route path="/contact">
               <Contact />
             </Route>
             <Route path="/">
               <Hp />
             </Route>
           </Switch>
        </div>
      </div>
    </Router>
  );
}

export default withNavigation(App);
