import React from 'react';

import { FocusableInput, FocusableButton, FocusableLink, FocusableTextarea } from './Controllers';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

function Restaurant(props) {
  return (
    <div>
        <img src="/restaurant.jpg" />
        <h1>Restaurant</h1>
        <h2>Pizza</h2>
        <dl>
          <dt>Marinára</dt>
          <dd>Tomato, mozzarella, ham, oliv, anchovies</dd>
          <dt>Margherita</dt>
          <dd>Tomato, mozzarella, slices of tomatoe, basil</dd>
          <dt>Al Prosciutto</dt>
          <dd>Tomato, mozzarella, ham</dd>
          <dt>Hawai</dt>
          <dd>Tomato, mozzarella, ham, pineapple</dd>
          <dt>Capricciosa</dt>
          <dd>Tomato, mozzarella, ham, mushrooms</dd>
          <dt>Al Funghi</dt>
          <dd>Tomato, mozzarella, mushrooms</dd>
          <dt>Al Salame</dt>
          <dd>Tomato, mozzarella, Ventricina piccante</dd>
          <dt>Messicana</dt>
          <dd>Tomato, mozzarella, onion, Ventricina piccante, egg, chilli, ram horns</dd>
          <dt>Al Prosciutto di Parma</dt>
          <dd>Tomato, mozzarella, prosciutto, arugula, parmesan</dd>
          <dt>Vegetariana</dt>
          <dd>Tomato, mozzarella, pepper, slices of tomatoes, onion, corn</dd>
          <dt>Frutti di Mare</dt>
          <dd>Tomato, mozzarella, see food and lemon</dd>
          <dt>Al Salmone</dt>
          <dd>Tomato, mozzarella, smoked salmone</dd>
          <dt>Al Tonno</dt>
          <dd>Tomato, mozzarella, tuna, red onion, garlic</dd>
          <dt>Tarent</dt>
          <dd>Tomato, mozzarella, ham, mushrooms, chicken,</dd>
          <dt>Pizza Fresh</dt>
          <dd>Tomato, mozzarella, spinach, chicken, red onion, garlic</dd>
        </dl>
    </div>
  );
}

export default Restaurant;
