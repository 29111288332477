import React from 'react';

import { withFocusable } from 'react-tv-navigation'

import {
  Link
} from "react-router-dom";

const Input = (props) => {
  const {setFocus} = props;
  //focused = (focused) ? 'focused' : 'unfocused'
  return (
    <input
      onClick={() => { setFocus() }}
      name={props.name}
      onChange={props.onChange}
      type={props.type}
      value={props.value}
    />
  );
}

const Textarea = (props) => {
  const {setFocus} = props;
  //focused = (focused) ? 'focused' : 'unfocused'
  return (
    <textarea
      onClick={() => { setFocus() }}
      name={props.name}
      onChange={props.onChange}
    >{props.children}</textarea>
  );
}

const Button = (props) => {
  return (
    <button
      onClick={props.onClick}
    >{props.children}</button>
  );
}

const NormalLink = (props) => {
  // const {focused, setFocus, focusPath} = props;
  return (
    <a
      href={props.href}
      onClick={props.onClick}
    >{props.children}</a>
  );
}

const RouteLink = (props) => {
  // const {focused, setFocus, focusPath} = props;
  return (
    <Link
      to={props.href}
      // onClick={props.onClick}
    >{props.children}</Link>
  );
}



export const FocusableTextarea = withFocusable(Textarea);
export const FocusableLink = withFocusable(NormalLink);
export const FocusableRouteLink = withFocusable(RouteLink);
export const FocusableInput = withFocusable(Input);
export const FocusableButton = withFocusable(Button);
