import React from 'react';

import { FocusableInput, FocusableButton, FocusableLink, FocusableTextarea } from './Controllers';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

function Map(props) {
  return (
    <div>
        <img src="/map.jpg" />
    </div>
  );
}

export default Map;
