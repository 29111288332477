import React from 'react';

import { FocusableInput, FocusableButton, FocusableLink, FocusableTextarea } from './Controllers';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

function Conctact(props) {
  return (
    <div>
        <img src="/contact.jpg" />
        <h1>Contact</h1>
        <h2>Address</h2>
        <address>
        42 avenue Gabriel<br />
        75008 Paris - France<br />
        Telephone: +33 1 58 36 60 60<br />
        reservations@lareserve-paris.com
        </address>
        <h2>Give us a feedback</h2>
        <p>Name<br />
        <FocusableInput
          name="asd"
          focusPath="item-3"
          />
          </p>
        <p>Messages<br />
        <FocusableTextarea
          focusPath="item-4"
        ></FocusableTextarea>
        </p>
        <p>
        <FocusableButton focusPath="item-2"
          onClick={(e) => {
            alert("Thanks a lot")
          }}
        >Submit</FocusableButton>
        </p>
    </div>
  );
}

export default Conctact;
