import React from 'react';

import { FocusableInput, FocusableButton, FocusableLink, FocusableTextarea } from './Controllers';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

function Checkout(props) {
  return (
    <div>
        <img src="/header.jpg" />
        <h1>Checkout</h1>
        <p>Your order of yours room is finshed after 53 minutes, you can checkout now</p>
        <p>Rules of checkout online:</p>
        <ul>
          <li>Gather your room key, possessions, and other checkout necessaries.</li>
          <li>Scan shelves, under beds, and outlets before leaving.</li>
          <li>Check the bathroom for forgotten items.</li>
          <li>Remove and pack all items in drawers and closets.</li>
        </ul>
        <FocusableButton focusPath="item-2"
          onClick={(e) => {
            alert("Done, you can now leave hotel");
          }}
        >All done and I want checkout now</FocusableButton>

    </div>
  );
}

export default Checkout;
